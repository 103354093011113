import { useScreen } from '~/modules/settings'
import { classNames } from '~/utils/class-names'

export type ContainerProps = JSX.IntrinsicElements['div'] & {
  as?: React.ElementType<any>
}

export const styles = {
  base: 'mx-auto w-full px-4',
}

export function Container({ className, as: Component = 'div', ...props }: ContainerProps) {
  const { isFullWidth } = useScreen()

  return (
    <Component
      className={classNames(styles.base, { 'max-w-7xl': !isFullWidth }, className)}
      {...props}
    />
  )
}

export function Padding({ className, as: Component = 'div', ...props }: ContainerProps) {
  return <Component className={classNames(styles.base, className)} {...props} />
}
